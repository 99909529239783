import React from 'react'

export default function Prolog() {
    return (
        <div className="prolog">
                <h6>אם יש משהו שתקופת הקורונה הוכיחה לנו, זה שכל עסק חייב אתר. הכול קורה כיום אונליין, וגם העסק שלך צריך להופיע שם!</h6>
                <h6>עם אתר מודרני, מהיר ומאובטח. אתר שמעוצב על פי האווירה הייחודית לעסק, ובעיקר בעיקר, אתר שמוכר.</h6>
                <h6><strong>
                אבל אמא'לה, מאיפה מתחילים??
                </strong></h6>
                <h6>אני כאן כדי לעשות סדר, לתכנן יחד וללוות – לא רק עד לתוצאה המושלמת, אלא גם אלמד אותך איך לנהל את האתר החדש.</h6>
                <h6> וזה לא נגמר שם, עבור מי שלא פנויי או מעוניין להתעסק בקשקושים הטכנולוגיים האלה בעצמו, אני מציעה גם ניהול של האתר הקיים. שינויים, עדכונים וכל מה שצריך :) </h6>

                <div className="pro-btn-wrap">
                    <a href="/portfolio">
                        <button className="submit pro-btn">להתרשמות מדוגמאות >></button>
                    </a>
                    <a href="/about">
                        <button className="submit pro-btn">למה לצאת לדרך דווקא איתי?</button>
                    </a>
                </div>
        </div>
    )
}
